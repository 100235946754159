import React, { useState } from "react";
import { Input, FormControl } from "native-base";
import { Controller } from "react-hook-form";
import { aw, ah } from "../utils";

const LeadsWatchInput = React.forwardRef((props, ref) => {
  return (
    <FormControl
      onFocus={props.onFocus}
      isRequired={props.isRequired}
      isInvalid={props.invalid}
      isReadOnly={props.isReadOnly || false}
      style={{
        height: ah(48),
        ...props.style,
      }}
    >
      {props.label && (
        <FormControl.Label
          style={{
            fontSize: aw(13),
            fontFamily: "RobotoMedium",
            color: props.invalid ? "red.500" : "rgba(102, 102, 102, 0.7)",
            // height: ah(19),
            ...props.labelstyle,
          }}
        >
          {props.label}
        </FormControl.Label>
      )}

      <Controller
        control={props.control}
        rules={props.rules}
        name={props.name}
        style={{
          zIndex: 1,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            color="black"
            tabIndex={props.tabIndex}
            isReadOnly={props.isReadOnly || false}
            ref={ref}
            size={props.size || "lg"}
            multiline={props.multiline || false}
            numberOfLines={props.numberOfLines || 1}
            minLength={props.minLength || 1}
            maxLength={props.maxLength || 255}
            isInvalid={props.invalid}
            autoComplete="off"
            autoFocus={props.autoFocus || false}
            type={props.type || "text"}
            onSubmitEditing={props.onSubmitEditing}
            onKeyPress={props.onKeyPress}
            borderRadius={props.borderRadius}
            borderColor={"#D1D5DB" || props.borderColor}
            onChangeText={(value) => {
              onChange(value);
              if (props.onChangeText) {
                props.onChangeText(value);
              }
            }}
            _focus={{
              bg: "white",
            }}
            onBlur={onBlur}
            value={props.captilize && value ? value.toUpperCase() : value || ""}
            variant={"outline"}
            invalidOutlineColor={"red.300"}
            focusOutlineColor={"#2230F8"}
            _invalid={{
              borderColor: "red.300",
              borderWidth: "1",
            }}
            _light={{
              borderWidth: "1",
            }}
            _dark={{
              borderWidth: "1",
            }}
            _web={{
              borderWidth: "1.5",
            }}
            style={{
              fontSize: aw(15),
              fontFamily: "RobotoRegular",
              backgroundColor: "white",
              zIndex: 1,
              height: props.height || ah(36),
            }}
            InputLeftElement={props.leftIcon || null}
            InputRightElement={props.rightIcon || null}
            placeholder={props.placeholder}
          />
        )}
      />
      <FormControl.ErrorMessage style={{ marginTop: ah(-3) }}>
        {props.error}
      </FormControl.ErrorMessage>
      <FormControl.HelperText style={{ marginTop: ah(-3) }}>
        {props.help}
      </FormControl.HelperText>
    </FormControl>
  );
});

export default LeadsWatchInput;
