import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

import { authReducer } from "./reducer/auth";
import { detailsReducer } from "./reducer/details";
import { activitiesReducer } from "./reducer/activity";

const reducer = combineReducers({
  auth: authReducer,
  details: detailsReducer,
  activities: activitiesReducer,
});
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsDenylist, actionsCreators and other options
});
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
export default store;
