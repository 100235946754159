import * as events from "../Events";
const initialState = {
  allDetails: null,
  newData: null,
  editedData: null,
  singleDetails: null,
};

export const detailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_DETAILS:
      return {
        ...state,
        allDetails: action.data,
      };
    case events.ADD_NEW_DETAILS:
      return {
        ...state,
        newData: action.data,
      };
    case events.EDIT_DETAILS:
      return {
        ...state,
        editedData: action.data,
      };
    case events.GET_SINGLE_DETAILS:
      return {
        ...state,
        singleDetails: action.data,
      };

    default:
      return state;
  }
};
