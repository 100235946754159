//  import { vw, vh, vmin, vmax } from "rxn-units";

// export const aw = (w) => {
//   const p = (w * 100) / 1366;
// return vw(p);
// };

// export const ah = (h) => {
//   const p = (h * 100) / 768;
//   return vh(p);
// };

export let DW = 1366;
export let DH = 768;

export const ah = (value) => {
  return `${(100 * ch(value)) / window.innerHeight}vh`;
};

export const aw = (value) => {
  return `${(100 * cw(value)) / window.innerWidth}vw`;
};

const cw = (w) => {
  return (window.innerWidth / DW) * w;
};
const ch = (h) => {
  return (window.innerHeight / DH) * h;
};
