import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchEyeIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="View_icon" transform="translate(-1138 -482)">
      <Rect
        id="Rectangle_803"
        data-name="Rectangle 803"
        width={aw(24)}
        height={ah(24)}
        rx={4}
        transform="translate(1138 482)"
        fill="none"
      />
      <Path
        id="Path_10304"
        data-name="Path 10304"
        d="M0-14.238a8.2,8.2,0,0,1,2.391.352,7.607,7.607,0,0,1,2,.984,14.947,14.947,0,0,1,1.652,1.3,11.835,11.835,0,0,1,1.3,1.441q.527.738.914,1.336a6.548,6.548,0,0,1,.563.949L9-7.488l-.105.211a5.171,5.171,0,0,1-.352.633q-.246.457-.527.914-.316.422-.809,1.055a11.274,11.274,0,0,1-.984,1.125A11.474,11.474,0,0,1,4.992-2.5a10.6,10.6,0,0,1-1.441.914,9.718,9.718,0,0,1-1.652.6A7.538,7.538,0,0,1,0-.738a7.511,7.511,0,0,1-2.391-.387,8.371,8.371,0,0,1-2-.949,14.947,14.947,0,0,1-1.652-1.3,11.835,11.835,0,0,1-1.3-1.441q-.527-.738-.914-1.3A8.066,8.066,0,0,1-8.824-7.1L-9-7.453A1.929,1.929,0,0,1-8.895-7.7q.07-.141.352-.633.246-.457.527-.914.316-.422.809-1.055a11.274,11.274,0,0,1,.984-1.125,11.474,11.474,0,0,1,1.23-1.055,10.6,10.6,0,0,1,1.441-.914A8.077,8.077,0,0,1-1.9-14.027,8.728,8.728,0,0,1,0-14.238Zm0,1.477a6.384,6.384,0,0,0-1.617.211,9.764,9.764,0,0,0-1.406.563,7.435,7.435,0,0,0-1.266.844q-.6.492-1.055.949-.422.492-.844,1.02-.422.563-.668.914-.246.387-.457.773.211.387.457.773a10.778,10.778,0,0,0,.668.914,13.019,13.019,0,0,0,.844,1.02q.457.457,1.055.949a8.915,8.915,0,0,0,1.266.809,6.293,6.293,0,0,0,1.406.563A6.384,6.384,0,0,0,0-2.25a6.384,6.384,0,0,0,1.617-.211,6.293,6.293,0,0,0,1.406-.562,8.915,8.915,0,0,0,1.266-.809,10.844,10.844,0,0,0,1.055-.984q.422-.457.844-.984a8.068,8.068,0,0,0,.668-.949q.246-.387.457-.738-.211-.387-.457-.773t-.668-.949q-.422-.527-.844-.984a8.383,8.383,0,0,0-1.055-.949,7.435,7.435,0,0,0-1.266-.844,9.764,9.764,0,0,0-1.406-.562A6.384,6.384,0,0,0,0-12.762Zm0,2.25a2.877,2.877,0,0,1,2.109.879,2.918,2.918,0,0,1,.879,2.145,2.877,2.877,0,0,1-.879,2.109A2.877,2.877,0,0,1,0-4.5a2.877,2.877,0,0,1-2.109-.879,2.877,2.877,0,0,1-.879-2.109,2.918,2.918,0,0,1,.879-2.145A2.877,2.877,0,0,1,0-10.512ZM0-9a1.376,1.376,0,0,0-1.055.457,1.376,1.376,0,0,0-.457,1.055,1.449,1.449,0,0,0,.457,1.055A1.431,1.431,0,0,0,0-6.012a1.431,1.431,0,0,0,1.055-.422,1.449,1.449,0,0,0,.457-1.055,1.376,1.376,0,0,0-.457-1.055A1.376,1.376,0,0,0,0-9Z"
        transform="translate(1150 502)"
        fill="#494440"
      />
    </G>
  </Svg>
);
export default LeadsWatchEyeIcon;
