import * as events from "../Events";
const initialState = {
  users: [],
  user: null,
  profile: null,
  updatedProfile: null,
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.REGISTER:
      return {
        ...state,
        users: action.data,
      };
    case events.SET_USER:
      return {
        ...state,
        user: action.data,
      };
    case events.LOGOUT:
      console.log("logout");
      return {
        ...state,
        user: null,
      };
    case events.FORGOT_PASSWORD:
      return {
        ...state,
      };
    case events.RESET_PASSWORD:
      return {
        ...state,
      };
    case events.UPDATE_PROFILE:
      return {
        ...state,
        profile: action.data,
      };
    case events.GET_PROFILE:
      return {
        ...state,
        profile: action.data,
      };
    default:
      return state;
  }
};
