import {
  HStack,
  Avatar,
  Text,
  Pressable,
  Modal,
  VStack,
  Stack,
  Divider,
  Box,
  useToast,
  Menu,
} from "native-base";
import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { aw, ah } from "../utils";
import LeadsWatchMenu from "./LeadsWatchMenu";
import LeadsWatchModal from "./LeadsWatchModal";
import LeadsWatchInput from "./LeadsWatchInput";
import LeadsWatchButton from "./LeadsWatchButton";
import { generateNewPassword, logout } from "../store/actions/auth";
import LeadsWatchEyeOffIcon from "./svg/LeadsWatchEyeOffIcon";
import LeadsWatchEyeIcon from "./svg/LeadsWatchEyeIcon";
import ToastAlert from "./ToastAlert";

const LeadsWatchTopBar = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [nShow, setNShow] = useState(false);
  const [cShow, setCShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    mode: "all",
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({
    mode: "all",
  });
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const navigate = useNavigate();
  const handleLogout = async () => {
    // try {
    //   await dispatch(logout());
    // } catch (err) {
    //   alert(err.message);
    // }
    localStorage.removeItem("auth");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("email");
    navigate("/");
    window.location.reload(false);
  };
  const handleEditPassword = () => {
    setOpenProfileModal(false);
    setResetPasswordModal(true);
  };
  const handleEditProfile = () => {
    setOpenProfileModal(false);
    reset1({
      firstname: localStorage.getItem("firstName"),
      lastname: localStorage.getItem("lastName"),
      email: localStorage.getItem("email"),
    });
    setEditProfile(true);
  };
  const handleUpdateProfile = async (data) => {
    setLoading(true);
    console.log(data);
    try {
      await dispatch(generateNewPassword({ ...data, changePassword: false }));
      localStorage.setItem("firstName", data.firstname);
      localStorage.setItem("lastName", data.lastname);
      localStorage.setItem("email", data.email);
      setEditProfile(false);
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={"Profile updated successfully"}
              // handleClose={() => toast.close()}
              status="success"
              title="success"
            />
          );
        },
      });
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="error"
            />
          );
        },
      });
    }
    setLoading(false);
  };
  const handleUpdatePassword = async (data) => {
    setLoading1(true);
    console.log(data);
    try {
      await dispatch(
        generateNewPassword({
          newpassword: data.npassword,
          password: data.password,
          confirmpassword: data.cPassword,
          changePassword: true,
        })
      );

      setResetPasswordModal(false);
      reset();
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={"password updated successfully"}
              // handleClose={() => toast.close()}
              status="success"
              title="success"
            />
          );
        },
      });
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="error"
            />
          );
        },
      });
    }
    setLoading1(false);
  };
  return (
    <>
      <HStack
        justifyContent={"space-between"}
        borderBottomColor={"#E6E7E9"}
        borderBottomWidth={ah(1)}
        height={ah(70)}
        width={aw(1350)}
        style={{
          background: "#FFFFFF 0% 0% no-repeat padding-box",
        }}
      >
        <HStack alignItems={"center"} height={ah(70)} width={aw(68)}>
          <Text
            marginLeft={aw(20)}
            style={{ color: "#0000FF", fontSize: aw(14), fontWeight: 700 }}
          >
            Leadswatch Referrals
          </Text>
        </HStack>

        <HStack alignItems={"center"} justifyContent={"space-evenly"}>
          <Pressable>
            <HStack
              style={{
                border: "3px solid #E6EBF2",
                borderRadius: aw(40),
                borderColor: "#E6EBF2",
              }}
            >
              <LeadsWatchMenu
                width={aw(120)}
                mr={aw(5)}
                title={
                  <Avatar
                    size={aw(36)}
                    // source={{
                    //   uri: image,
                    // }}
                  />
                }
              >
                <Menu.Item
                  bgColor="white"
                  _hover={{ bgColor: "#F5F7FB" }}
                  onPress={() => setOpenProfileModal(true)}
                >
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    profile
                  </Text>
                </Menu.Item>
                <Menu.Item
                  bgColor="white"
                  _hover={{ bgColor: "#F5F7FB" }}
                  onPress={handleLogout}
                >
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    Logout
                  </Text>
                </Menu.Item>
              </LeadsWatchMenu>
            </HStack>
          </Pressable>
        </HStack>
      </HStack>
      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
        isOpen={openProfileModal}
        onClose={() => setOpenProfileModal(false)}
        // safeAreaTop={true}
      >
        <Modal.Content
          bg={"white"}
          maxWidth={aw(468)}
          maxHeight={ah(768)}
          height="full"
          style={{
            marginLeft: "auto",
            marginRight: 0,
            marginBottom: 0,
            marginTop: 0,
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            overflow: "visible",
            zIndex: 1001,
          }}
        >
          <Modal.Header
            mt={0}
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 7px 10px #0000000D",
              borderRadius: "14px 14px 0px 0px",
              opacity: 1,
              borderColor: "#D7D7D7",
            }}
          >
            <HStack>
              <Modal.CloseButton
                mt={0}
                width={aw(30)}
                height={ah(30)}
                color="white"
                style={{
                  position: "relative",
                  right: aw(60),
                  top: ah(-13),
                  backgroundColor: "#212121",
                }}
              />
              <Text
                // fontFamily={"RobotoMedium"}
                // fontSize={aw(20)}
                // color="#0D2238"
                style={{
                  fontFamily: "RobotoMedium",
                  fontSize: aw(20),
                  color: "#0D2238",
                }}
              >
                Profile
              </Text>
            </HStack>
          </Modal.Header>

          <Modal.Body>
            <VStack space={ah(28)}>
              <HStack justifyContent={"flex-end"}>
                <VStack>
                  <Text
                    onPress={handleEditProfile}
                    fontFamily="RobotoRegular"
                    fontSize={aw(14)}
                    color="#0000FF"
                  >
                    Edit
                  </Text>
                  <Divider bgColor="#0000FF" />
                </VStack>
              </HStack>
              <HStack>
                <Text
                  width={aw(180)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  First Name
                </Text>
                <Text
                  color="#212121"
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                >
                  {localStorage.getItem("firstName")}
                </Text>
              </HStack>
              <HStack>
                <Text
                  width={aw(180)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  LastName
                </Text>
                <Text
                  color="#212121"
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                >
                  {localStorage.getItem("lastName")}
                </Text>
              </HStack>
              <HStack>
                <Text
                  width={aw(180)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  Email
                </Text>
                <Text
                  color="#212121"
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                >
                  {localStorage.getItem("email")}
                </Text>
              </HStack>
            </VStack>
            <Divider my={ah(24)} bgColor="#EAEBEF" />
            <HStack justifyContent={"space-between"}>
              <Text
                fontFamily="RobotoRegular"
                fontSize={aw(14)}
                color="#494440"
              >
                password
              </Text>
              <VStack>
                <Text
                  onPress={handleEditPassword}
                  fontFamily="RobotoRegular"
                  fontSize={aw(14)}
                  color="#0000FF"
                >
                  Edit
                </Text>
                <Divider bgColor="#0000FF" />
              </VStack>
            </HStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <LeadsWatchModal
        height={ah(500)}
        width={aw(400)}
        showModal={resetPasswordModal}
        onClose={() => setResetPasswordModal(false)}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Create new password
            </Text>
          </HStack>
        }
      >
        <VStack px={aw(20)}>
          <Text fontSize={aw(14)} fontFamily="RobotoRegular" color="#0000004D">
            Protect sensitive information and unautroized access with strong and
            unique password
          </Text>
          <Text
            fontFamily={"RobotoMedium"}
            fontSize={aw(15)}
            color={"red.600"}
            mt={ah(5)}
            ml={ah(4)}
          ></Text>
          <LeadsWatchInput
            control={control}
            name="password"
            borderRadius={"10px"}
            borderWidth={"2px"}
            style={{ marginTop: aw(17), width: aw(320) }}
            height={ah(50)}
            placeholder="Enter Password"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors["password"] ? true : false}
            error={errors["password"] ? errors["password"].message : ""}
            rightIcon={
              !show ? (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
            type={show ? "text" : "password"}
          />

          <LeadsWatchInput
            borderRadius={"10px"}
            borderWidth={"2px"}
            labelstyle={{ marginBottom: aw(6) }}
            style={{ marginTop: aw(17), width: aw(320) }}
            height={ah(50)}
            control={control}
            name="npassword"
            placeholder="New password"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.npassword ? true : false}
            error={errors.npassword ? errors.npassword.message : ""}
            type={nShow ? "text" : "password"}
            rightIcon={
              !nShow ? (
                <Pressable onPress={() => setNShow(!nShow)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setNShow(!nShow)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
          />
          <LeadsWatchInput
            borderRadius={"10px"}
            borderWidth={"2px"}
            labelstyle={{ marginBottom: aw(6) }}
            style={{ marginTop: aw(17), width: aw(320) }}
            height={ah(50)}
            control={control}
            name="cPassword"
            placeholder="confirm password"
            // rules={{
            //   required: {
            //     value: true,
            //     validate: (value) => value === getValues("password"),
            //   },
            // pattern: {
            //   value:
            //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
            // },
            // }}
            rules={{
              required: true,
              message: "Enter a valid password",
              validate: (value) => value === getValues("npassword"),
            }}
            invalid={errors.cPassword ? true : false}
            error={errors.cPassword ? errors.cPassword.message : ""}
            type={cShow ? "text" : "password"}
            rightIcon={
              !cShow ? (
                <Pressable onPress={() => setCShow(!cShow)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setCShow(!cShow)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
          />
          <LeadsWatchButton
            onPress={handleSubmit(handleUpdatePassword)}
            isLoading={loading1}
            color="#FAFBFD"
            style={{
              width: aw(320),
              height: ah(50),
              borderRadius: "10px",
              marginTop: aw(19),
            }}
            label="Reset password"
            bg="#0000FF"
            borderColor="#FAFBFD"
          />
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        height={ah(1000)}
        width={aw(400)}
        showModal={editProfile}
        onClose={() => setEditProfile(false)}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Edit Profile
            </Text>
          </HStack>
        }
      >
        <VStack px={aw(20)}>
          <Text
            fontFamily={"RobotoMedium"}
            fontSize={aw(15)}
            color={"red.600"}
            mt={ah(5)}
            ml={ah(4)}
          ></Text>
          <LeadsWatchInput
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                FirstName
              </Text>
            }
            control={control1}
            name="firstname"
            borderRadius={"10px"}
            borderWidth={"2px"}
            style={{ marginBottom: ah(20), width: aw(320) }}
            height={ah(50)}
            placeholder="Enter First Name"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors["firstname"] ? true : false}
            error={errors["firstname"] ? errors["firstname"].message : ""}
          />

          <LeadsWatchInput
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                LastName
              </Text>
            }
            borderRadius={"10px"}
            borderWidth={"2px"}
            // labelstyle={{ marginBottom: aw(6) }}
            style={{ width: aw(320), marginTop: ah(20), marginBottom: ah(20) }}
            height={ah(50)}
            control={control1}
            name="lastname"
            placeholder="Enter Last Name"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.lastname ? true : false}
            error={errors.lastname ? errors.lastname.message : ""}
            // type={rShow ? "text" : "password"}
            // rightIcon={
            //   !rShow ? (
            //     <Pressable onPress={() => setRShow(!rShow)}>
            //       <LeadsWatchEyeOffIcon />
            //     </Pressable>
            //   ) : (
            //     <Pressable onPress={() => setRShow(!rShow)}>
            //       <LeadsWatchEyeIcon />
            //     </Pressable>
            //   )
            // }
          />
          <LeadsWatchInput
            isReadOnly={true}
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Email
              </Text>
            }
            style={{ marginTop: ah(20), marginBottom: ah(30), width: aw(320) }}
            borderRadius={"10px"}
            borderWidth={"2px"}
            height={ah(50)}
            control={control1}
            name="email"
            placeholder="Enter Email"
            // rules={{
            //   required: {
            //     value: true,
            //     validate: (value) => value === getValues("password"),
            //   },
            // pattern: {
            //   value:
            //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
            // },
            // }}
            rules={{
              required: true,
            }}
            invalid={errors.email ? true : false}
            error={errors.email ? errors.email.message : ""}

            // type={cShow ? "text" : "password"}
            // rightIcon={
            //   !cShow ? (
            //     <Pressable onPress={() => setCShow(!cShow)}>
            //       <LeadsWatchEyeOffIcon />
            //     </Pressable>
            //   ) : (
            //     <Pressable onPress={() => setCShow(!cShow)}>
            //       <LeadsWatchEyeIcon />
            //     </Pressable>
            //   )
            // }
          />
          <LeadsWatchButton
            onPress={handleSubmit1(handleUpdateProfile)}
            isLoading={loading}
            color="#FAFBFD"
            style={{
              width: aw(320),
              height: ah(50),
              borderRadius: "10px",
              marginTop: aw(19),
            }}
            label="update"
            bg="#0000FF"
            borderColor="#FAFBFD"
          />
        </VStack>
      </LeadsWatchModal>
    </>
  );
};

export default LeadsWatchTopBar;
