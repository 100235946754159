import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchPasswordIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Password_default_icon" transform="translate(-268 -407)">
      <Rect
        id="Rectangle_287"
        data-name="Rectangle 287"
        width={aw(24)}
        height={ah(24)}
        transform="translate(268 407)"
        fill="none"
      />
      <G id="Lock" transform="translate(272 409.478)">
        <Path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M8.6,6.312V4.289A4.3,4.3,0,0,0,0,4.27V6.312"
          transform="translate(3.626 0.75)"
          fill="none"
          stroke={props.color || "#919191"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <Path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M10.774,11.188h-7.2A3.573,3.573,0,0,1,0,7.615V3.573A3.573,3.573,0,0,1,3.573,0h7.2a3.573,3.573,0,0,1,3.573,3.573V7.615A3.573,3.573,0,0,1,10.774,11.188Z"
          transform="translate(0.75 6.995)"
          fill="none"
          stroke={props.color || "#919191"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <Path
          id="Stroke_5"
          data-name="Stroke 5"
          d="M.5,0V2.093"
          transform="translate(7.424 11.542)"
          fill="none"
          stroke={props.color || "#919191"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
      </G>
    </G>
  </Svg>
);
export default LeadsWatchPasswordIcon;
