import {
  Text,
  View,
  Modal,
  Input,
  FormControl,
  Button,
  Pressable,
  HStack,
  Box,
} from "native-base";
import React from "react";
import { aw, ah } from "../utils";

const LeadsWatchModal = (props) => {
  return (
    <Modal
      // style={{
      //   // left: ah(298),
      //   width: aw(770),
      //   background: "#FFFFFF 0% 0% no-repeat padding-box",

      //   borderRadius: "14px 14px 0px 0px",
      //   opacity: 1,
      //   borderColor:"#D7D7D7"

      // }}
      isOpen={props.showModal}
      onClose={props.onClose}
      transparent={false}
      style={{
        boxShadow: "0px 7px 10px #0000000D",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        opacity: 1,
        zIndex: 999,
        position: "relative",
      }}
    >
      <Modal.Content maxHeight={props.height} maxWidth={props.width}>
        {/* <Modal.Content> */}
        <Modal.CloseButton
          color="#0D2238"
          _icon={{
            size: aw(10),
          }}
          _hover={{
            bg: "#D7D7D7",
          }}
        />
        <Modal.Header
          style={{
            // left: ah(298),
            width: aw(770),
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 7px 10px #0000000D",
            borderRadius: "14px 14px 0px 0px",
            opacity: 1,
            borderColor: "#D7D7D7",
          }}
        >
          {props.heading}
        </Modal.Header>
        <Modal.Body backgroundColor={"#FFFFFF"}>{props.children}</Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default LeadsWatchModal;
