import { VStack, Text, NativeBaseProvider, HStack } from "native-base";
import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Login from "./screens/auth/Login";
import Register from "./screens/auth/Register";
import Details from "./screens/dashboard/Details";
import Activity from "./screens/dashboard/Activity";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("auth");
  useEffect(() => {
    if (token) {
      if (location.pathname === "/register") {
        navigate("/");
      }
    }
  }, []);
  return token ? (
    <Routes>
      <Route path="/" element={<Details />} />
      <Route path="/activity" element={<Activity />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Register />} />
    </Routes>
  );
};

export default App;
