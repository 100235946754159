import * as events from "../Events";
const initialState = {
  allActivities: null,
};

export const activitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_ACTIVITY:
      return {
        ...state,
        allActivities: action.data,
      };

    default:
      return state;
  }
};
