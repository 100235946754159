import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchDownArrow(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(20)}
      height={ah(20)}
      // viewBox="6 4 15 20"
      viewBox="5 8 17 17"
      {...props}
    >
      <Path data-name="Rectangle 766" fill="none" d="M0 0H24V24H0z" />
      <Path
        data-name="Union 3"
        d="M3.541 12L0 8.46l1.18-1.17 2.36 2.35L5.9 7.29l1.18 1.17z"
        transform="translate(8 2.71)"
        fill={props.color || "#212121"}
      />
    </Svg>
  );
}

export default LeadsWatchDownArrow;
