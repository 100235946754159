import React, { useState } from "react";
import { Menu, Box, Pressable, HStack } from "native-base";
import { aw, ah } from "../utils";

const LeadsWatchMenu = (props) => {
  return (
    <Box style={{ ...props.boxStyle }}>
      <Menu
        // position="absolute"
        mr={props.mr}
        // mr={aw(8)}
        ml={props.ml}
        mt={props.mt || 0.8}
        mb={props.mb}
        bgColor="white"
        position={props.position}
        onOpen={props.onOpen}
        onClose={props.onClose}
        closeOnSelect={props.closeOnSelect || true}
        defaultIsOpen={props.defaultIsOpen || false}
        isOpen={props.isOpen}
        // shouldOverlapWithTrigger={props.shouldOverlapWithTrigger || false}
        w={props.width || aw(190)}
        style={{ ...props.menuStyle }}
        trigger={(triggerProps) => {
          return (
            <Pressable
              accessibilityLabel="More options menu"
              {...triggerProps}
              position="relative"
            >
              {props.title}
            </Pressable>
          );
        }}
      >
        {props.children}
      </Menu>
    </Box>
  );
};

export default LeadsWatchMenu;
