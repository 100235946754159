import React, { useState, useEffect } from "react";
import {
  Text,
  HStack,
  VStack,
  Box,
  Checkbox,
  Center,
  Image,
  Divider,
  Spinner,
  Alert,
  useToast,
  IconButton,
  CloseIcon,
  Pressable,
  FlatList,
  ScrollView,
  Modal,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { aw, ah } from "../../utils";
import ToastAlert from "../../components/ToastAlert";

import LeadsWatchSelect from "../../components/LeadsWatchSelect";

import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchTopBar from "../../components/LeadsWatchTopBar";
import { allActivities } from "../../store/actions/activity";

const Activity = () => {
  const details = useSelector((state) => state.details.allActivities);
  const toast = useToast();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectCount, setSeletCount] = useState("10");
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      select: "10",
    },
  });

  const handleChangeSelect = (value) => {
    setSeletCount(value);
    setCurrentPage(1);
  };
  const fetchDeatils = async () => {
    setIsLoading(true);
    try {
      await dispatch(allActivities(currentPage, selectCount));
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="error"
            />
          );
        },
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDeatils();
  }, [currentPage, selectCount]);

  return (
    <VStack width={aw(1248)} height={ah(768)}>
      <LeadsWatchTopBar />

      <VStack ml={aw(55)} space={ah(18)} width={aw(1248)}>
        <HStack
          justifyContent="space-between"
          alignItems={"center"}
          width={aw(1248)}
        >
          <VStack justifyContent={"center"}>
            <HStack space={aw(8)} alignItems={"baseline"}>
              <Text
                fontSize={aw(26)}
                color={"#121233"}
                fontFamily="RobotoMedium"
              >
                Activity
              </Text>
              <Text
                fontSize={aw(18)}
                color={"#919191"}
                fontFamily="RobotoMedium"
              >
                {/* {details && details.count} */}
              </Text>
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              Your all activity list will be shown here
            </Text>
          </VStack>
        </HStack>
        <Divider bg="#E6E7E9" />
        <VStack height={ah(35)}>
          <HStack justifyContent={"space-between"}>
            <HStack height={ah(32)} alignItems="center">
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Show Entries :{" "}
              </Text>
              <Center>
                <LeadsWatchSelect
                  control={control}
                  name={"select"}
                  list={["10", "15", "20", "25"]}
                  value={selectCount}
                  handleChange={(value) => handleChangeSelect(parseInt(value))}
                  width={aw(70)}
                  height={ah(32)}
                />
              </Center>
            </HStack>
          </HStack>
        </VStack>
        <Box>
          <VStack width={aw(1248)}>
            <HStack
              width={aw(1248)}
              justifyContent="space-evenly"
              alignItems={"center"}
              bgColor="#D7D7D7"
              style={{
                height: ah(60),
                background: "#F9FAFB 0% 0% no-repeat padding-box",
                borderLeft: "1px solid #D7D7D7",
                borderRight: "1px solid #D7D7D7",
                borderTop: "1px solid #D7D7D7",
                borderBottom: "1px solid #D7D7D7",
                borderTopLeftRadius: aw(10),
                borderTopRightRadius: aw(10),
              }}
            >
              {["S.No", "Title", "Description", "Created"].map((val) => (
                <HStack
                  width={aw(200)}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={val}
                  ml={aw(10)}
                >
                  <Text
                    pl={aw(30)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoMedium"}
                    color="#212121"
                  >
                    {val}
                  </Text>
                </HStack>
              ))}
            </HStack>
            {!isLoading ? (
              <ScrollView
                maxHeight={ah(400)}
                showsVerticalScrollIndicator={false}
                style={{
                  borderBottomRightRadius: aw(10),
                  borderBottomLeftRadius: aw(10),
                  borderbottomWidth: "1px solid #D7D7D7",
                  borderTopWidth: "0px",
                  borderColor: "#D7D7D7",
                  borderWidth: "1px",
                }}
              >
                <FlatList
                  ListEmptyComponent={
                    <HStack
                      width={aw(1248)}
                      justifyContent="center"
                      alignItems={"center"}
                      height={ah(400)}
                    >
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        No data found
                      </Text>
                    </HStack>
                  }
                  data={details && details.data}
                  keyExtractor={(item) => item._id}
                  renderItem={({ item, index }) => (
                    <HStack
                      width={aw(1248)}
                      justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                        minHeight: ah(58),
                        borderTopWidth: index === 0 ? 0 : "1px",
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderBottomWidth: "0px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      {["index", "title", "desc", "created"].map((val) => (
                        <HStack
                          width={aw(200)}
                          justifyContent="flex-start"
                          alignItems={"center"}
                          key={val}
                          ml={aw(10)}
                        >
                          <Text
                            pl={aw(30)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                            color="#212121"
                          >
                            {item[val]}
                          </Text>
                        </HStack>
                      ))}
                    </HStack>
                  )}
                />
              </ScrollView>
            ) : (
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(400)}
                style={{
                  border: "1px solid #D7D7D7",
                  borderTopWidth: 0,
                  borderBottomLeftRadius: aw(10),
                  borderBottomRightRadius: aw(10),
                }}
              >
                <Spinner size="lg" color="#2230F8" />
              </HStack>
            )}
          </VStack>
        </Box>
        <VStack bottom={aw(5)} position="fixed">
          <LeadsWatchPagination
            totalCount={details && details.count ? details.count : 0}
            currentPage={currentPage}
            pageSize={selectCount}
            onPageChange={(currentPage) => setCurrentPage(currentPage)}
          />
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Activity;
