import {
  Text,
  HStack,
  VStack,
  Alert,
  IconButton,
  CloseIcon,
  useToast,
} from "native-base";
const ToastAlert = (props) => {
  const toast = useToast();
  return (
    <Alert
      mr={1}
      width={"100%"}
      alignSelf="center"
      flexDirection="row"
      status={props.status}
      variant={"left-accent"}
    >
      <VStack space={1} flexShrink={1} w="100%">
        <HStack
          flexShrink={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon />
            <Text
              fontSize="md"
              fontWeight="medium"
              flexShrink={1}
              color={"darkText"}
            >
              {props.title}
            </Text>
          </HStack>
          {/* <IconButton
            variant="unstyled"
            icon={<CloseIcon size="3" />}
            _icon={{
              color: "darkText",
            }}
            onPress={() => toast.closeAll()}
          /> */}
        </HStack>
        <Text px="6" color={"darkText"}>
          {props.desc}
        </Text>
      </VStack>
    </Alert>
  );
};
export default ToastAlert;
