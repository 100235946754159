import React from "react";
import {
  FormControl,
  Select,
  Center,
  WarningOutlineIcon,
  NativeBaseProvider,
  Text,
} from "native-base";
import LeadsWatchDownArrow from "./svg/LeadsWatchDownArrow";
import { aw, ah } from "../utils";
import { Controller } from "react-hook-form";
const LeadsWatchSelect = (props) => {
  return (
    <FormControl
      width={props.width}
      height={props.height}
      // isRequired={props.error&&props.isRequired}
      isInvalid={props.invalid}
    >
      {props.label && (
        <FormControl.Label
          style={{
            fontSize: aw(13),
            fontFamily: "Poppins_medium",
            color: props.invalid ? "red.500" : "rgba(102, 102, 102, 0.7)",
            height: ah(19),
            ...props.labelstyle,
          }}
        >
          {props.label}
        </FormControl.Label>
      )}
      <Controller
        control={props.control}
        rules={props.rules}
        name={props.name}
        style={{
          zIndex: 1,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            color="#919191"
            borderColor={props.borderColor || "#D1D5DB"}
            rounded={5}
            _customDropdownIconProps={{
              color: "muted.500",
              mr: "3",
              size: aw(10),
              p: "1",
            }}
            _hover={{
              borderColor: "#D1D5DB",
            }}
            isInvalid={props.invalid}
            isDisabled={props.isDisabled}
            isHovered={props.isHovered}
            isFocused={props.isFocused}
            selectedValue={value}
            onValueChange={(value) => {
              onChange(value);
              if (props.handleChange) {
                props.handleChange(value);
              }
            }}
            accessibilityLabel={props.accessibilityLabel}
            placeholder={props.placeholder}
            _focus={{
              bgColor: "white",
              borderColor: "#0000FF",
              borderWidth: "1px",
            }}
            placeholderTextColor={props.placeholderTextColor}
            _selectedItem={{
              bg: "white",
              endIcon: <LeadsWatchDownArrow />,
            }}
            variant={props.variant}
            focusOutlineColor="#2230F8"
            _invalid={{
              borderColor: "red.300",
              borderWidth: "1",
            }}
            invalidOutlineColor={"red.300"}
            onBlur={onBlur}
            h={props.height || ah(32)}
          >
            {props.list.map((val) => (
              <Select.Item
                key={val}
                value={props.notToLowerCase ? val : val.toLowerCase()}
                label={val}
              />
            ))}
          </Select>
        )}
      />
      <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
        {props.error}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};
export default LeadsWatchSelect;
