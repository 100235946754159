import React from "react";
import { Button, Icon, Tooltip } from "native-base";
import { aw, ah } from "../utils";

const LeadsWatchButton = (props) => {
  return (
    <Tooltip label={"No Access"} isDisabled={props.isDisabled ? false : true}>
      <Button
        isDisabled={props.isDisabled}
        variant={props.variant || "solid"}
        onPress={props.onPress}
        borderWidth={props.borderWidth}
        isLoading={props.isLoading}
        isLoadingText={props.isLoadingText}
        leftIcon={props.leftIcon || null}
        rightIcon={props.rightIcon || null}
        _text={{
          color: props.color || "#212121",
          fontWeight: props.fontWeight,
          fontFamily: props.fontFamily || "RobotoRegular",
          textTransform: "capitalize",
          opacity: props.opacity,
          fontSize: props.fontSize || aw(14),
        }}
        _pressed={{
          // backgroundColor: "transparent",
          ...props._pressed,
        }}
        _hover={{
          backgroundColor: props.bg || "#FAFBFD",
          ...props._hover,
        }}
        borderColor={props.borderColor || "#D1D5DB"}
        borderBottomColor={props.borderBottomColor}
        style={{ ...styles.default, ...props.style }}
        bg={props.bg || "#FAFBFD"}
      >
        {props.label}
      </Button>
    </Tooltip>
  );
};
const styles = {
  default: {
    height: ah(50),
    // width: aw(10),
    borderRadius: aw(5),
    borderWidth: 1,
  },
};
export default LeadsWatchButton;

// import React, { useRef } from "react";
// import { Button } from "native-base";
// import {
//   widthPercentageToDP as vw,
//   heightPercentageToDP as vh,
// } from "react-native-responsive-screen";

// const ShadowButton = React.forwardRef((props, ref) => {
//   let dw = props.label.length * 10;
//   const space = props.space ? props.space * 10 : 10;
//   dw = dw + space;
//   return (
//     <Button
//       width={vw(dw || 100)}
//       rounded={"full"}
//       _text={{
//         color: props.variant ? "rgba(0,0,0,0.5)" : "white",
//         fontSize: vw(15),
//         fontFamily: "Poppins",
//       }}
//       _focus={{
//         height: vh(36),
//         width: vw(dw || 100),
//         _text: {
//           color: props.variant ? "rgba(0,0,0,0.3)" : "white",
//           fontFamily: "Poppins",
//           fontSize: vw(15),
//         },
//         _important: {
//           background:
//             "transparent linear-gradient(124deg, rgba(186, 71, 243, 1) 0%, rgba(125, 43, 233, 1) 100%) 0% 0% no-repeat padding-box",
//         },
//         background:
//           "transparent linear-gradient(124deg, rgba(186, 71, 243, 1) 0%, rgba(125, 43, 233, 1) 100%) 0% 0% no-repeat padding-box",
//         ...props._focus,
//       }}
//       _pressed={{
//         height: vh(36),
//         width: vw(dw || 100),
//         _text: {
//           color: props.variant ? "rgba(0,0,0,0.3)" : "white",
//           fontFamily: "Poppins",
//           fontSize: vw(15),
//         },
//         background: props.variant
//           ? "white"
//           : "transparent linear-gradient(124deg, rgba(186, 71, 243, 1) 0%, rgba(125, 43, 233, 1) 100%) 0% 0% no-repeat padding-box",
//         ...props._pressed,
//         // backgroundColor:
//         //   "transparent linear-gradient(124deg, rgba(186, 71, 243, 1) 0%, rgba(125, 43, 233, 1) 100%) 0% 0% no-repeat padding-box",
//       }}
//       size={"md"}
//       variant={"unstyled"}
//       {...props}
//       style={{
//         width: vw(dw || 100),
//         height: vh(36),
//         background: props.variant
//           ? "white"
//           : "transparent linear-gradient(124deg, rgba(186, 71, 243, 1) 0%, rgba(125, 43, 233, 1) 100%) 0% 0% no-repeat padding-box",
//         ...props.style,
//       }}
//     >
//       {props.label}
//     </Button>
//   );
// });

// export default ShadowButton;
